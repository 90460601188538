<template>
  <div>
    <div>
      <a-form-model
        :model="formInline"
        @submit.native.prevent
        :colon="colonfalse"
        :rules="rules"
        ref="ruleForm"
      >
        <div class="title">基本信息</div>
        <a-form-model-item label="所属公司" prop="wpBcId">
          <a-select
            v-model="formInline.wpBcId"
            class="search-expand-value"
            :disabled="type == 'edit' ? true : false"
          >
            <a-select-option
              v-for="opt in filters.GetCompanies"
              :key="opt.bc_id"
              :value="opt.bc_id"
              >{{ opt.bc_name }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="工序名称">
          <a-input v-model="formInline.wpName" placeholder=""> </a-input>
        </a-form-model-item>
        <a-form-model-item label="施工顺序">
          <a-input v-model="formInline.wpOrder" placeholder=""> </a-input>
        </a-form-model-item>
        <div class="title title1">验收(选填)</div>
        <a-form-model-item label="验收方式" prop="wpCheckWay">
          <a-select v-model="formInline.wpCheckWay" class="search-expand-value">
            <a-select-option
              v-for="opt in filters.wpCheckWay"
              :key="opt.CheckwayId"
              :value="opt.CheckwayId"
              >{{ opt.CheckwayName }}</a-select-option
            >
          </a-select>
        </a-form-model-item>

        <div
          class="displayflex"
          v-for="(domain, index) in dynamicValidateForm.domains"
          :key="index"
          v-bind="index === 0 ? formItemLayout : {}"
        >
          <!-- label="选择并且或或者" -->
          <div class="isOrAndSelect" v-if="index > 0">
            <a-form-model-item prop="wpCheckPost">
              <a-select v-model="domain.isOrAnd" class="search-expand-value">
                <a-select-option
                  v-for="opt in filters.isOrAnd"
                  :key="opt.key"
                  :value="opt.key"
                  >{{ opt.value }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
          </div>

          <a-form-model-item
            prop="wpCheckPost"
            label="验收人岗位"
            :required="false"
          >
            <!-- :label="index === 0 ? '验收人岗位' : ''" -->
            <a-select
              mode="multiple"
              v-model="domain.post"
              class="search-expand-value"
            >
              <a-select-option
                v-for="opt in filters.wpCheckPost"
                :key="opt.CheckPostId"
                :value="opt.CheckPostId"
                >{{ opt.CheckPostName }}</a-select-option
              >
            </a-select>
            <a-icon
              v-if="dynamicValidateForm.domains.length > 1"
              class="dynamic-delete-button"
              type="minus-circle-o"
              :disabled="dynamicValidateForm.domains.length === 1"
              @click="removeDomain(domain)"
            />
          </a-form-model-item>
        </div>
        <div>
          <a-form-item v-bind="formItemLayoutWithOutLabel">
            <a-button type="dashed" style="width: 60%" @click="addDomain">
              <a-icon type="plus" /> 添加验收岗位
            </a-button>
          </a-form-item>
        </div>

        <a-form-model-item
          label="是否需要业主验收"
          prop="wpCheckOwner"
          class="wpCheckOwnerName"
        >
          <a-radio-group v-model="formInline.wpCheckOwner">
            <a-radio value="Y"> 是 </a-radio>
            <a-radio value="N"> 否 </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item>
          <a-button :style="{ marginRight: '18px' }" @click="onClose">
            取消
          </a-button>
          <a-button
            type="primary"
            html-type="submit"
            @click="submitForm('dynamicValidateForm')"
          >
            提交
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import { GetCompanies } from "@/api/device";
import { AddWorkProce, UpdatWorkProce } from "@/api/apiJF/template";
export default {
  name: "ConstructProcedureEdit",
  components: {},
  props: {
    record: {
      type: Object,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      colonfalse: false,
      rules: {
        wpBcId: [
          {
            required: true,
            message: "请选择所属公司",
            trigger: "change",
          },
          // {
          //   // min: 1,
          //   // // max: 5,
          //   // message: "分公司不能为空",
          //   // trigger: "blur",
          // },
        ],
      },
      formInline: {
        wpBcId: "", //分公司id
      },
      formInlineEdit: {
        wpBcId: "", //分公司id
        wpName: "", //工序名称
        wpOrder: "", //施工顺序
        wpCheckWay: "", //验收方式
        wpCheckOwner: "", //是否需要业主验收
        // wpCheckPost: "", //验收岗位
        wpCheckList: "",
      },
      wpCheckPost: [
        { post: [], isOrAnd: "" },
        { post: [], isOrAnd: "" },
        { post: [], isOrAnd: "" },
        { post: [], isOrAnd: "" },
        { post: [], isOrAnd: "" },
        { post: [], isOrAnd: "" },
        { post: [], isOrAnd: "" },
        { post: [], isOrAnd: "" },
        { post: [], isOrAnd: "" },
      ], //验收岗位
      filters: {
        GetCompanies: [],
        wpCheckWay: [
          { CheckwayId: 1, CheckwayName: "远程验收" },
          { CheckwayId: 2, CheckwayName: "现场验收" },
          { CheckwayId: 3, CheckwayName: "远程+现场验收" },
          { CheckwayId: 4, CheckwayName: "远程/现场验收" },
        ],
        wpCheckPost: [
          { CheckPostId: "1", CheckPostName: "工程管家" },
          { CheckPostId: "2", CheckPostName: "项目经理" },
        ],
        isOrAnd: [
          { key: "and", value: "且" },
          { key: "or", value: "或" },
        ],
      },
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 4 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 20 },
        },
      },
      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: { span: 24, offset: 0 },
          sm: { span: 20, offset: 4 },
        },
      },
      dynamicValidateForm: {
        domains: [],
      },
    };
  },

  created() {
    // console.log("record", this.type, this.record);

    // 获取分公司信息
    GetCompanies().then((res) => {
      this.filters.GetCompanies = res.data;
    });
  },
  mounted() {
    if (this.type == "edit") {
      for (let key in this.formInlineEdit) {
        for (let el in this.record) {
          if (key == el) {
            this.formInlineEdit[key] = this.record[el];
          }
        }
      }
      this.formInlineEdit.wpId = this.record.wpId;
      this.formInline = this.formInlineEdit;
      // console.log();
      let wpCheckList = JSON.parse(this.record.wpCheckList);
      wpCheckList.forEach((el) => {
        el.post = el.post.split(",");
      });
      this.dynamicValidateForm.domains = wpCheckList;
      console.log("wpCheckList", wpCheckList);
      // console.log("1111", this.formInline);
      // console.log(this.wpCheckPost);
    }
  },
  watch: {},
  methods: {
    // 添加或修改
    update(NewWpCheckPost) {
      if (this.type == "add") {
        // this.formInline.wpCheckList = NewWpCheckPost;
        let params = this.formInline;

        console.log("params", params);
        AddWorkProce(params).then((res) => {
          // console.log(res);
          if (res.code == "200") {
            this.$message.success("添加成功");
            this.onClose();
          } else {
            this.$message.error(res.msg);
          }
        });
      } else if (this.type == "edit") {
        this.formInline.wpId = this.record.wpId;
        this.formInline.createTime = this.record.createTime;
        let params = this.formInline;
        UpdatWorkProce(params).then((res) => {
          // console.log(res);
          if (res.code == "200") {
            this.$message.success("修改成功");
            this.onClose();
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    onClose() {
      this.$emit("onClose");
    },
    removeDomain(item) {
      let index = this.dynamicValidateForm.domains.indexOf(item);
      if (index !== -1) {
        this.dynamicValidateForm.domains.splice(index, 1);
      }
    },
    addDomain() {
      this.dynamicValidateForm.domains.push({
        value: "",
        key: Date.now(),
      });
    },
    submitForm(formName) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // alert("submit!");
          let NewWpCheckPost = [];
          this.dynamicValidateForm.domains.forEach((el) => {
            if (el.post != "") {
              NewWpCheckPost.push({
                post: el.post.toString(),
                isOrAnd: el.isOrAnd ? el.isOrAnd : "",
              });
            }
          });
          this.formInline.wpCheckList = JSON.stringify(NewWpCheckPost);
          console.log("提交", this.formInline);
          this.update();
        } else {
          console.log("error submit!!");
          return false;
        }
      });

      // console.log("NewWpCheckPost", NewWpCheckPost);
    },
  },
  destroyed() {},
};
</script>

<style lang="less" scoped>
.title {
  width: 100%;
  text-align: left;
  padding-left: 30px;
  margin: 20px 0;
  font-weight: 800;
  font-size: 16px;
}

.title1 {
  margin-top: 50px;
}
/deep/.ant-row {
  display: flex;
  width: 90%;
}
/deep/ .ant-form-item-control-wrapper {
  width: 80%;
}
.wpCheckOwnerName {
  /deep/ .ant-form-item-control-wrapper {
    text-align: left;
  }
}
/deep/ .ant-form-item-label {
  width: 200px;
}
/deep/ .ant-form {
  text-align: center;
}
.displayflex {
  display: flex;
  // justify-content: left;
  // width: 100%;
  /deep/ .ant-form-item-control-wrapper {
    width: 80%;
  }
  /deep/.ant-form-item-label {
    width: 200px;
  }
  /deep/.isOrAndSelect {
    width: 90px;
    margin-right: -110px;
    margin-left: 20px;
    z-index: 11;
  }
  /deep/.ant-form-item-children {
    display: flex;
  }
}
.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>